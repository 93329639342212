@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

body.body-lock {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes recordingBlink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.animate-blink {
  animation: recordingBlink 1.5s ease-in-out infinite;
}

@keyframes dot-typing {
  0% {
    box-shadow:
      9984px 0 0 0 black,
      9994px 0 0 0 black,
      10004px 0 0 0 black;
  }
  16.667% {
    box-shadow:
      9984px -10px 0 0 black,
      9994px 0 0 0 black,
      10004px 0 0 0 black;
  }
  33.333% {
    box-shadow:
      9984px 0 0 0 black,
      9994px 0 0 0 black,
      10004px 0 0 0 black;
  }
  50% {
    box-shadow:
      9984px 0 0 0 black,
      9994px -10px 0 0 black,
      10004px 0 0 0 black;
  }
  66.667% {
    box-shadow:
      9984px 0 0 0 black,
      9994px 0 0 0 black,
      10004px 0 0 0 black;
  }
  83.333% {
    box-shadow:
      9984px 0 0 0 black,
      9994px 0 0 0 black,
      10004px -10px 0 0 black;
  }
  100% {
    box-shadow:
      9984px 0 0 0 black,
      9994px 0 0 0 black,
      10004px 0 0 0 black;
  }
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: black;
  color: black;
  box-shadow:
    9984px 0 0 0 black,
    9994px 0 0 0 black,
    10004px 0 0 0 black;
  animation: dot-typing 1.5s infinite linear;
}

.pt-safe {
  padding-top: env(safe-area-inset-top);
}
